import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import CrudTable from '../../crud/CrudTable'
import { billService as service } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ToastContainer, toast } from 'react-toastify'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { pad } from '../../../utils/object'
import { Box } from '@mui/system'
import { Button, Grid } from '@mui/material'
import Modal from '@mui/material/Modal'
import DownloadIcon from '@mui/icons-material/Download'
import MailIcon from '@mui/icons-material/Mail'

const moment = require('moment')

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const initFilter = {
    number: null,
    serie: null,
    user: null,
    from: moment().format('YYYY') + '-01-01T00:00:00Z',
    to: null,
    state: null,
    route: null,
    paymenttype: null,
}

export default function Bill() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [
        { openRect, billRect, filter, evetclick, ids, typePrint },
        setState,
    ] = useState({
        openRect: false,
        billRect: {},
        filter: { ...initFilter },
        evetclick: 0,
        ids: [],
        typePrint: -1,
    })

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'serie',
            col: 3,
        },
        {
            type: 'input',
            name: 'number',
            inputtype: 'number',
            autoFocus: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'from',
            inputtype: 'date',
            col: 3,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'date',
            col: 3,
        },
    ]

    const handlePrint = (bill) => {
        var isGenerate =
            bill &&
            bill.number &&
            bill.number != '' &&
            bill.state &&
            bill.state.id &&
            bill.state.id != 1
        if (isGenerate) {
            let fileName =
                'Factura' +
                (bill.serie ? bill.serie : '') +
                pad(+bill.number, 5) +
                '.pdf'
            service.print(bill.id, fileName, (data, erro) => {
                toast.success('Se ha descargado la factura', {
                    position: toast.POSITION.TOP_CENTER,
                })
            })
        } else {
            toast.error('Solo se puede imprimir factura ya generadas', {
                position: toast.POSITION.TOP_CENTER,
            })
        }
    }

    const handlePrintDirect = (bill) => {
        var isGenerate =
            bill &&
            bill.number &&
            bill.number != '' &&
            bill.state &&
            bill.state.id &&
            bill.state.id != 1
        if (isGenerate) {
            let fileName =
                'Factura' +
                (bill.serie ? bill.serie : '') +
                pad(+bill.number, 5) +
                '.pdf'
            service.printDirect(bill.id, fileName, (data, erro) => {
                toast.success('Se ha descargado la factura', {
                    position: toast.POSITION.TOP_CENTER,
                })
            })
        } else {
            toast.error('Solo se puede imprimir factura ya generadas', {
                position: toast.POSITION.TOP_CENTER,
            })
        }
    }

    const handleSend = (bill) => {
        service.send(bill.id, (data, erro) => {
            toast.success('Se ha enviado al cliente corrcetamente', {
                position: toast.POSITION.TOP_CENTER,
            })
        })
    }

    const paintCell = {
        base: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)}</>
            } else {
                return <></>
            }
        },
        iva: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)}</>
            } else {
                return <></>
            }
        },
        total: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)}</>
            } else {
                return <></>
            }
        },
    }

    const handleRecti = (bill) => {
        var isGenerate =
            bill &&
            bill.number &&
            bill.number != '' &&
            bill.state &&
            bill.state.id &&
            bill.state.id != 1
        if (isGenerate) {
            var update = {
                billRect: bill,
                openRect: true,
            }
            setState((currentState) => ({ ...currentState, ...update }))
        } else {
            toast.error(
                'Solo se puede hacer rectificativas de factura ya generadas',
                { position: toast.POSITION.TOP_CENTER }
            )
        }
    }

    const handleCloseRect = () => {
        var update = {
            openRect: true,
        }
        setState((currentState) => ({ ...currentState, ...update }))
    }

    const conditionIsGenerate = (bill) => {
        var isGenerate =
            bill &&
            bill.number &&
            bill.number != '' &&
            bill.state &&
            bill.state.id &&
            bill.state.id != 1
        return isGenerate
    }

    useEffect(() => {
        if (evetclick > 0) {
            setState((currentState) => ({
                ...currentState,
                ...{ evetclick: 0 },
            }))
            if (typePrint == 1) {
                let fileName = 'Facturas.pdf'
                console.log(filter)
                service.printSeveralsFilter(filter, fileName, (data, erro) => {
                    toast.success('Se han descargado las facturas', {
                        position: toast.POSITION.TOP_CENTER,
                    })
                })
            } else if (typePrint == 2) {
                let fileName = 'Facturas.pdf'
                service.printDirectSeveralsFilter(
                    filter,
                    fileName,
                    (data, erro) => {
                        toast.success('Se han descargado las facturas', {
                            position: toast.POSITION.TOP_CENTER,
                        })
                    }
                )
            } else if (typePrint == 5) {
                let fileName = 'ListadoIva.xlsx'
                service.allFilterIvaExcel(filter, fileName, (data, erro) => {
                    toast.success('Se han descargado el listado de iva', {
                        position: toast.POSITION.TOP_CENTER,
                    })
                })
            }
        }
    }, [filter, evetclick, typePrint])

    const setFilter = (filter) => {
        var update = {
            filter: filter,
        }
        setState((currentState) => ({ ...currentState, ...update }))
    }

    useEffect(() => {
        return () => {
            setState({
                openRect: false,
                billRect: {},
                filter: { ...initFilter },
                evetclick: 0,
                ids: [],
                typePrint: -1,
            })
        }
    }, [])

    return (
        <>
            <CrudTable
                service={service}
                urledit={''}
                urlnew={''}
                filter={initFilter}
                i18n={'bill'}
                setFilter={setFilter}
                editable={false}
                hiddenButton={true}
                deleteaction={false}
                fieldstable={[
                    'serie',
                    'number',
                    'date',
                    'client.name|client.lastname|client.email|client.phone',
                    'base',
                    'iva',
                    'total',
                ]}
                notexport={true}
                defaultValues={{ serie: '', number: 'Borrador' }}
                moreActions={[
                    {
                        handle: handlePrintDirect,
                        icon: <PrintIcon />,
                        condition: conditionIsGenerate,
                    },
                    {
                        handle: handlePrint,
                        icon: <DownloadIcon />,
                        condition: conditionIsGenerate,
                    },
                ]}
                paintCell={paintCell}
                labels={{
                    'client.name|client.lastname|client.email|client.phone': t(
                        'bill.field.client.id'
                    ),
                }}
                estructureForm={createEstructureFilter}
                order={false}
                selectable={false}
                expendedFilter={false}
                orderColumns={{
                    serie: 'serie',
                    number: 'number',
                    date: 'date',
                }}
            />
            <ToastContainer />
        </>
    )
}
